import { Button, Space } from 'antd'
import { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useQueryParams } from 'use-query-params'
import { H4 } from '@/components/common/typography'
import { EntryLayout } from '@/components/layout/entry-layout/entry-layout'
import { Page } from '@/hooks/use-authorization/constants'
import routes from '@/hooks/use-authorization/routes'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { UserGroup } from '@/types/user'
import { ampli } from '@/utils/ampli'
import { NonNullStringParam } from '@/utils/use-query-params'

const LoginLayout: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()
  const [{ lang }] = useQueryParams({
    lang: NonNullStringParam,
  })

  if (breakpoint === 'PC') {
    return (
      <EntryLayout
        header={{
          extra: (
            <Space size='middle'>
              <H4>{formatMessage({ id: 'account:no_product_account_yet' })}</H4>
              <Button
                type='primary'
                onClick={() => {
                  ampli.clicksRegister({ from: Page.Login })
                  routes.register.goto({}, { lang, from: Page.Login })
                }}
              >
                {formatMessage({ id: 'general:register' })}
              </Button>
            </Space>
          ),
        }}
      >
        {children}
      </EntryLayout>
    )
  }

  return <EntryLayout>{children}</EntryLayout>
}

const RegisterLayout: FunctionComponent<{
  userGroup?: UserGroup
  children: ReactNode
}> = ({ userGroup = 'ad', children }) => {
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()
  const [{ lang }] = useQueryParams({
    lang: NonNullStringParam,
  })

  if (breakpoint === 'PC') {
    return (
      <EntryLayout
        header={{
          extra: (
            <Space size='middle'>
              <H4>
                {formatMessage({ id: 'account:already_has_product_account' })}
              </H4>
              <Button
                type='primary'
                onClick={() => {
                  routes.login.goto({}, { lang, userGroup })
                }}
              >
                {formatMessage({ id: 'general:login' })}
              </Button>
            </Space>
          ),
        }}
      >
        {children}
      </EntryLayout>
    )
  }

  return <EntryLayout>{children}</EntryLayout>
}

export { EntryLayout, LoginLayout, RegisterLayout }
