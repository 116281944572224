import { Button, Flex } from 'antd'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { StyleParagraph } from '@/components/common/typography'
import routes from '@/hooks/use-authorization/routes'
import { useI18n } from '@/hooks/use-i18n'

type LoginAuthInfoFormFooterProps = {
  loading?: boolean
  formId?: string
}
const LoginAuthInfoFormFooter: FunctionComponent<
  LoginAuthInfoFormFooterProps
> = ({ loading = false, formId }) => {
  const { formatMessage } = useIntl()
  const { locale } = useI18n()

  return (
    <Wrapper>
      <Flex gap={32} vertical={true}>
        <StyleParagraph height={24} size={14}>
          <Link href={routes.forgetPassword.stringifyUrl({}, { lang: locale })}>
            {formatMessage({ id: 'account:login_forget_password' })}
          </Link>
        </StyleParagraph>
        <Flex align='center' gap={16} vertical={true}>
          <Button
            block
            form={formId}
            htmlType='submit'
            loading={loading}
            type='primary'
          >
            {formatMessage({ id: 'general:login' })}
          </Button>
          <StyleParagraph height={24} size={14}>
            {formatMessage({ id: 'account:no_product_account_yet' })}
            <Link href={routes.register.stringifyUrl({}, { lang: locale })}>
              {formatMessage({ id: 'account:login_go_to_sign_up' })}
            </Link>
          </StyleParagraph>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 16px;

  a {
    text-decoration: underline;
  }
`

export default LoginAuthInfoFormFooter
