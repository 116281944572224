import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import BackgroundImage from '@/assets/images/entry-layout-background-image.webp'
import { EntryLayoutFooter } from '@/components/layout/entry-layout/entry-layout-footer'
import { EntryLayoutHeader } from '@/components/layout/entry-layout/entry-layout-header'
import {
  TrialCodeInfoAlert,
  KolTrialCodeInfoAlert,
} from '@/components/register/trial-code-info-alert'
import { useAuth } from '@/hooks/use-auth'
import { Page } from '@/hooks/use-authorization/constants'
import { useCurrentTrialCodeInfoQuery } from '@/hooks/use-current-trial-code-info-query'

type EntryLayoutProps = {
  children: ReactNode
  header?: {
    extra?: ReactNode
  }
}

export const EntryLayout: FunctionComponent<EntryLayoutProps> = ({
  children,
  header,
}) => {
  const router = useRouter()
  const { data: trialCodeInfo } = useCurrentTrialCodeInfoQuery()
  const { userInfo } = useAuth()

  const renderTrialCodeInfoAlert = (): ReactNode => {
    if (!trialCodeInfo || trialCodeInfo.status !== 'active') {
      return null
    }

    if (
      router.pathname === Page.RegisterKOL ||
      router.pathname === Page.VerifyAccount ||
      userInfo?.type === 'kol' ||
      userInfo?.type === 'kolAgency'
    ) {
      return <KolTrialCodeInfoAlert info={trialCodeInfo} />
    }

    return <TrialCodeInfoAlert info={trialCodeInfo} />
  }

  return (
    <>
      {renderTrialCodeInfoAlert()}
      <Container>
        <EntryLayoutHeader extra={header?.extra} />
        <SlotContainer>{children}</SlotContainer>
        <EntryLayoutFooter />
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: url(${BackgroundImage.src}) center/cover no-repeat;
`

const SlotContainer = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 16px 24px;
`
