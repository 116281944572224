import { CSSProperties, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

type CardProps = {
  className?: string
  style?: CSSProperties
  children: ReactNode
  renderHeader?: () => ReactNode
}

export const Card: FunctionComponent<CardProps> = ({
  className,
  style,
  children,
  renderHeader,
}) => {
  return (
    <Container className={className} style={style}>
      {renderHeader && <Header>{renderHeader()}</Header>}
      <Body>{children}</Body>
    </Container>
  )
}

const Container = styled.div`
  width: fit-content;
  background-color: ${(props): string => props.theme.colors.background.white};
  box-shadow: 0px 4px 20px 0px #00000040;
  border-radius: 8px;
`

const Header = styled.div``

const Body = styled.div`
  padding: 40px 48px;
`
