import { GoogleOAuthProvider } from '@react-oauth/google'
import { FunctionComponent } from 'react'
import useGoogleOAuthLogin, {
  GoogleSocialData,
} from '@/components/social/hooks/use-google-o-auth-login'
import {
  FacebookButton,
  GoogleButton,
  InstagramButton,
  SocialButtonProps,
} from '@/components/social/oauth-button'
import env from '@/utils/env'
import {
  createFacebookLoginCallback,
  FacebookLoginCallbackOptions,
  FacebookLoginCallbackReturnValues,
} from '@/utils/facebook/create-facebook-login-callback'

type SsoButtonProps = Pick<SocialButtonProps, 'actionType'>

type SsoFacebookButtonProps = SsoButtonProps &
  FacebookLoginCallbackOptions & {
    onAuthorizationSuccess: (data: FacebookLoginCallbackReturnValues) => void
    onAuthorizationFail: (error: Error) => void
  }

export const SsoFacebookButton: FunctionComponent<SsoFacebookButtonProps> = ({
  actionType,
  scopes,
  fields,
  onAuthorizationSuccess,
  onAuthorizationFail,
}) => {
  const facebookLoginCallback = createFacebookLoginCallback({
    scopes,
    fields,
  })

  const handleClick = async (): Promise<void> => {
    try {
      const response = await facebookLoginCallback()
      onAuthorizationSuccess(response)
    } catch (error) {
      onAuthorizationFail(error)
    }
  }

  return <FacebookButton block actionType={actionType} onClick={handleClick} />
}

type SsoGoogleButtonProps = SsoButtonProps & {
  onAuthorizationSuccess: (data: GoogleSocialData) => void
  onAuthorizationFail: (error: unknown) => void
}

const InnerSsoGoogleButton: FunctionComponent<SsoGoogleButtonProps> = ({
  actionType,
  onAuthorizationSuccess,
  onAuthorizationFail,
}) => {
  const handleClick = useGoogleOAuthLogin(
    onAuthorizationSuccess,
    onAuthorizationFail,
  )

  return <GoogleButton block actionType={actionType} onClick={handleClick} />
}

export const SsoGoogleButton: FunctionComponent<SsoGoogleButtonProps> = ({
  actionType,
  onAuthorizationSuccess,
  onAuthorizationFail,
}) => {
  return (
    <GoogleOAuthProvider
      clientId={`${env.NEXT_PUBLIC_GOOGLE_APP_ID}.apps.googleusercontent.com`}
    >
      <InnerSsoGoogleButton
        actionType={actionType}
        onAuthorizationFail={onAuthorizationFail}
        onAuthorizationSuccess={onAuthorizationSuccess}
      />
    </GoogleOAuthProvider>
  )
}

type SsoInstagramButtonProps = SsoButtonProps &
  FacebookLoginCallbackOptions & {
    onAuthorizationSuccess: (data: FacebookLoginCallbackReturnValues) => void
    onAuthorizationFail: (error: Error) => void
  }

export const SsoInstagramButton: FunctionComponent<SsoInstagramButtonProps> = ({
  actionType,
  scopes,
  fields,
  onAuthorizationSuccess,
  onAuthorizationFail,
}) => {
  /** TODO:
   * Instagram SSO SDK will be corrected in the near future,
   * so we name it instagram first even though the current implementation is using Facebook SDK.
   * */
  const instagramLoginCallback = createFacebookLoginCallback({
    scopes,
    fields,
  })

  const handleClick = async (): Promise<void> => {
    try {
      const response = await instagramLoginCallback()
      onAuthorizationSuccess(response)
    } catch (error) {
      onAuthorizationFail(error)
    }
  }

  return <InstagramButton block actionType={actionType} onClick={handleClick} />
}
