import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { UserGroup } from '@/types/user'

type LoginDesktopIdentityTabProps = {
  value?: UserGroup
  onChange?: (value: UserGroup) => void
}

const LoginDesktopIdentityTabs: FunctionComponent<
  LoginDesktopIdentityTabProps
> = ({ value = 'ad', onChange }) => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <Tab $isActive={value === 'ad'} onClick={() => onChange?.('ad')}>
        <Text>{formatMessage({ id: 'general:identity_ad' })}</Text>
      </Tab>
      <Tab $isActive={value === 'kol'} onClick={() => onChange?.('kol')}>
        <Text>{`${formatMessage({ id: 'general:identity_kol' })}/${formatMessage({ id: 'general:identity_kolAgency' })}`}</Text>
      </Tab>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
`

const activeStyles = css`
  background-color: ${(props): string => props.theme.colors.brand.primary};
  color: ${(props): string => props.theme.colors.text.white};
`

const inactiveStyles = css`
  background-color: ${(props): string => props.theme.colors.base.blue.blue8};
  color: ${(props): string => props.theme.colors.base.blue.blue5};
`

const Tab = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  user-select: none;
  cursor: pointer;

  ${({ $isActive = false }): ReturnType<typeof css> =>
    $isActive ? activeStyles : inactiveStyles}
`

const Text = styled.h5`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
`

export default LoginDesktopIdentityTabs
