import React, { FunctionComponent } from 'react'
import styled, {
  css,
  FlattenSimpleInterpolation,
  useTheme,
} from 'styled-components'
import { useQueryParams } from 'use-query-params'
import { useUpdateUserInfoMutation } from '@/api/user-api'
import { GoogleRecaptchaFooter } from '@/components/common/google-recaptcha-footer'
import { LanguageSelect } from '@/components/common/language-select'
import { StyleParagraph } from '@/components/common/typography'
import SafeNewTabLink from '@/components/safe-new-tab-link'
import { useAuth } from '@/hooks/use-auth'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useI18n } from '@/hooks/use-i18n'
import { isSupportedLanguage } from '@/i18n/languages'
import { ampli } from '@/utils/ampli'
import logger from '@/utils/logger'
import { NonNullStringParam } from '@/utils/use-query-params'

const COPYRIGHT_FONT_SIZE = 14
const COPYRIGHT_LINE_HEIGHT = 24

const BasicFooter: FunctionComponent = () => {
  const { language } = useI18n()
  const [{ from, lang }, setQuery] = useQueryParams({
    from: NonNullStringParam,
    lang: NonNullStringParam,
  })

  const theme = useTheme()
  const breakpoint = useBreakpoint()
  const isPC = breakpoint === 'PC'

  const { userInfo } = useAuth()
  const [updateUserInfo] = useUpdateUserInfoMutation()

  const handleLanguageSelect = async (value: string): Promise<void> => {
    if (userInfo) {
      try {
        await updateUserInfo({ language: value }).unwrap()
        ampli.changeLanguage({ language: value })
      } catch (error) {
        logger.error(error)
      }
    }

    setQuery({ from, lang: value })
  }

  return (
    <BasicFooterWrapper $isPC={isPC}>
      <LanguageSelect
        defaultValue={isSupportedLanguage(lang) ? lang : language.i18nCode}
        onSelect={handleLanguageSelect}
      />
      <StyleParagraph
        color={theme.colors.base.grey.grey2}
        height={COPYRIGHT_LINE_HEIGHT}
        size={COPYRIGHT_FONT_SIZE}
      >
        Copyright © {new Date().getFullYear()}{' '}
        <SafeNewTabLink href='https://ikala.ai/'>iKala</SafeNewTabLink> All
        Rights Reserved.
      </StyleParagraph>
    </BasicFooterWrapper>
  )
}

export const EntryLayoutFooter: FunctionComponent = () => {
  return (
    <Wrapper>
      <BasicFooter />
      <GoogleRecaptchaFooter />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 40px 24px 24px 24px;
`

const desktopBasicFooterWrapperStyle = css`
  flex-direction: row;
  gap: 0 24px;
`

const mobileBasicFooterWrapperStyle = css`
  flex-direction: column;
  gap: 16px 0;
`

const BasicFooterWrapper = styled.div<{ $isPC: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ $isPC }): FlattenSimpleInterpolation =>
    $isPC ? desktopBasicFooterWrapperStyle : mobileBasicFooterWrapperStyle}
`
