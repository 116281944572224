import Link from 'next/link'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import LogoHorizontal from '@/components/common/logo-horizontal'
import routes from '@/hooks/use-authorization/routes'
import { Breakpoint, useBreakpoint } from '@/hooks/use-breakpoint'
import { useI18n } from '@/hooks/use-i18n'

const LogoSizeMap: Record<Breakpoint, { width: number; height: number }> = {
  PC: {
    width: 107,
    height: 40,
  },
  mobile: {
    width: 98,
    height: 36,
  },
}

type EntryLayoutHeaderProps = {
  extra?: ReactNode
}

export const EntryLayoutHeader: FunctionComponent<EntryLayoutHeaderProps> = ({
  extra,
}) => {
  const { locale } = useI18n()
  const breakpoint = useBreakpoint()
  const isPC = breakpoint === 'PC'

  return (
    <Container $isPC={isPC}>
      <Link href={routes.login.stringifyUrl({}, { lang: locale })}>
        <LogoHorizontal
          height={LogoSizeMap[breakpoint].height}
          width={LogoSizeMap[breakpoint].width}
        />
      </Link>
      {extra}
    </Container>
  )
}

const desktopContainerStyle = css`
  padding: 16px 36px;
`

const mobileContainerStyle = css`
  padding: 14px 24px;
`

const Container = styled.div<{ $isPC: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ $isPC }): FlattenSimpleInterpolation =>
    $isPC ? desktopContainerStyle : mobileContainerStyle}
`
