import { Icon } from '@buggy/shared'
import { Alert } from 'antd'
import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import { useIntl } from '@/i18n/hooks/use-intl'
import { TrialCodeInfo } from '@/types/schema/trial-code-schema'

interface TrialCodeInfoAlertProps {
  info: TrialCodeInfo
}

export const TrialCodeInfoAlert: FunctionComponent<TrialCodeInfoAlertProps> = ({
  info,
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  return (
    <StyledAlert
      banner
      message={
        <MessageWrapper>
          <Icon fontSize={24} name='gift' stroke={theme.colors.plan.premium} />
          {formatMessage(
            { id: 'redeem_trial:alert_text' },
            { days: info.duration, quota: info.quota },
          )}
        </MessageWrapper>
      }
      showIcon={false}
      type='success'
    />
  )
}

export const KolTrialCodeInfoAlert: FunctionComponent<
  TrialCodeInfoAlertProps
> = ({ info }) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  return (
    <StyledAlert
      banner
      message={
        <MessageWrapper>
          <Icon fontSize={24} name='gift' stroke={theme.colors.plan.premium} />
          {formatMessage(
            { id: 'redeem_trial:kol_alert_text' },
            { days: info.duration, quota: info.quota },
          )}
        </MessageWrapper>
      }
      showIcon={false}
      type='success'
    />
  )
}

const StyledAlert = styled(Alert)`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.border.border} !important;
`

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
