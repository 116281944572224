import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { StyleParagraph } from '@/components/common/typography'
import { UserGroup } from '@/types/user'

type LoginMobileIdentityTabProps = {
  value?: UserGroup
  onChange?: (value: UserGroup) => void
}

const LoginMobileIdentityTabs: FunctionComponent<
  LoginMobileIdentityTabProps
> = ({ value = 'ad', onChange }) => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <Tab $isActive={value === 'ad'} onClick={() => onChange?.('ad')}>
        <StyleParagraph color='inherent' height={24} size={15} weight={700}>
          {formatMessage({ id: 'general:identity_ad' })}
        </StyleParagraph>
      </Tab>
      <Tab $isActive={value === 'kol'} onClick={() => onChange?.('kol')}>
        <StyleParagraph
          color='inherent'
          height={24}
          size={15}
          weight={700}
        >{`${formatMessage({ id: 'general:identity_kol' })}/${formatMessage({ id: 'general:identity_kolAgency' })}`}</StyleParagraph>
      </Tab>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const activeStyles = css`
  color: ${(props): string => props.theme.colors.brand.primary};
  border-bottom: 3px solid
    ${(props): string => props.theme.colors.brand.primary};
`

const inactiveStyles = css`
  color: ${(props): string => props.theme.colors.base.grey.grey2};
`

const Tab = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  user-select: none;
  cursor: pointer;

  ${({ $isActive = false }): ReturnType<typeof css> =>
    $isActive ? activeStyles : inactiveStyles}
`

export default LoginMobileIdentityTabs
