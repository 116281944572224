import { useCallback } from 'react'
import { GoogleSocialData } from '@/components/social/hooks/use-google-o-auth-login'
import { UserType } from '@/hooks/use-authorization/constants'
import { useSocialLogin } from '@/hooks/use-social-login'
import { SupportedLanguages } from '@/i18n/config'
import { SocialLoginResponse } from '@/types/schema/login-schema'

export type GoogleSocialLoginPayload = {
  oAuthType: 'google'
  oAuthUserId: GoogleSocialData['id']
  token: GoogleSocialData['accessToken']
  email: GoogleSocialData['email']
  trial_code?: string
}

export const useGoogleSocialLogin = (): ((
  socialData: GoogleSocialLoginPayload,
  {
    language,
    type,
    trial_code,
  }: {
    language: SupportedLanguages
    /** NOTE: type is required when register, but isn't when login */
    type?: UserType
    trial_code?: string
  },
) => Promise<SocialLoginResponse>) => {
  const socialLogin = useSocialLogin()

  return useCallback(
    (
      socialData: GoogleSocialLoginPayload,
      {
        language,
        type,
        trial_code,
      }: { language: SupportedLanguages; type: UserType; trial_code?: string },
    ): Promise<SocialLoginResponse> => {
      return socialLogin({
        ...socialData,
        language,
        type,
        trial_code,
      })
    },
    [socialLogin],
  )
}
