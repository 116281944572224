import { Modal } from 'antd'
import { useIntl } from '@/i18n/hooks/use-intl'

export const useOpenAuthorizationIncompleteModal = (): ((
  platform: string,
) => void) => {
  const { formatMessage } = useIntl()

  return (platform: string): void => {
    Modal.confirm({
      title: formatMessage(
        { id: 'general:account_authorization_failed' },
        { platformTitle: platform },
      ),
      content: formatMessage({
        id: 'general:account_facebook_authorization_failed_insufficient_data',
      }),
      okText: formatMessage({ id: 'general:btn_text_i_see' }),
      cancelButtonProps: { style: { display: 'none' } },
    })
  }
}
