import { Icon } from '@buggy/shared'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import { StyleParagraph } from '@/components/common/typography'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { exhaustiveCheck } from '@/utils/exhaustive-check'

const SOCIAL_ICON_SIZE = 20

type SocialButtonType = 'register' | 'login' | 'bind'

export type SocialButtonProps = ButtonProps & {
  actionType: SocialButtonType
}

const getSSOButtonTextI18nId = (type: SocialButtonType): I18nId => {
  switch (type) {
    case 'register':
      return 'account:register_sso_sign_up'
    case 'login':
      return 'account:register_sso_sign_in'
    case 'bind':
      return 'account:register_sso_bind'
    default:
      return exhaustiveCheck(type)
  }
}

export const FacebookButton: FunctionComponent<SocialButtonProps> = ({
  actionType,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  return (
    <StyledButton {...props}>
      <Icon
        color={theme.colors.social.facebook}
        fontSize={SOCIAL_ICON_SIZE}
        name='brand-facebook-circle-filled'
      />
      <ButtonText>
        {formatMessage(
          { id: getSSOButtonTextI18nId(actionType) },
          { ssoPlatform: 'Facebook' },
        )}
      </ButtonText>
    </StyledButton>
  )
}

export const GoogleButton: FunctionComponent<SocialButtonProps> = ({
  actionType,
  ...props
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledButton {...props}>
      <Icon fontSize={SOCIAL_ICON_SIZE} name='brand-google-filled' />
      <ButtonText>
        {formatMessage(
          { id: getSSOButtonTextI18nId(actionType) },
          { ssoPlatform: 'Google' },
        )}
      </ButtonText>
    </StyledButton>
  )
}

export const InstagramButton: FunctionComponent<SocialButtonProps> = ({
  actionType,
  ...props
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledButton {...props}>
      <Icon fontSize={SOCIAL_ICON_SIZE} name='brand-instagram-filled' />
      <ButtonText>
        {formatMessage(
          { id: getSSOButtonTextI18nId(actionType) },
          { ssoPlatform: 'Instagram' },
        )}
      </ButtonText>
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
`

const ButtonText = styled(StyleParagraph)`
  flex: 1;
  line-height: 1.4;
`
