import { api } from '@/api/api'
import { FetchTrialCodeInfoRequest } from '@/types/api/trial-code'
import {
  TrialCodeInfo,
  trialCodeInfoSchema,
} from '@/types/schema/trial-code-schema'

const trial = api.injectEndpoints({
  endpoints: (build) => ({
    fetchTrialCodeInfo: build.query<TrialCodeInfo, FetchTrialCodeInfoRequest>({
      query: ({ code }) => {
        return `/trial-codes/${code}`
      },
      extraOptions: {
        dataSchema: trialCodeInfoSchema,
      },
    }),
  }),
})

export const { useFetchTrialCodeInfoQuery, useLazyFetchTrialCodeInfoQuery } =
  trial
