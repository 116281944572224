import { Icon } from '@buggy/shared'
import { Alert, Divider, Flex, notification, Space } from 'antd'
import Cookies from 'js-cookie'
import React, { FunctionComponent, ReactNode, useId, useState } from 'react'
import styled from 'styled-components'
import { useLoginMutation } from '@/api/user-api'
import { Card } from '@/components/common/card/card'
import { H2 } from '@/components/common/typography'
import LoginForm, { LoginFormValues } from '@/components/form/login-form'
import LoginAuthInfoFormFooter from '@/components/login/login-auth-info-form-footer'
import LoginDesktopIdentityTabs from '@/components/login/login-desktop-identity-tabs'
import LoginMobileIdentityTabs from '@/components/login/login-mobile-identity-tabs'
import { GoogleSocialData } from '@/components/social/hooks/use-google-o-auth-login'
import {
  SsoFacebookButton,
  SsoGoogleButton,
  SsoInstagramButton,
} from '@/components/social/sso-button'
import { CookieName } from '@/constants/cookie-name'
import {
  META_AD_AUTH_SCOPES,
  META_KOL_AUTH_SCOPES,
} from '@/constants/meta-auth-request'
import { UserType } from '@/hooks/use-authorization/constants'
import routes from '@/hooks/use-authorization/routes'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import {
  FacebookSocialLoginPayload,
  useFacebookSocialLogin,
} from '@/hooks/use-facebook-social-login'
import {
  GoogleSocialLoginPayload,
  useGoogleSocialLogin,
} from '@/hooks/use-google-social-login'
import { useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { LoginResponse } from '@/types/api-v1-types'
import { UserGroup } from '@/types/user'
import {
  hasFailedAuthenticationError,
  isRTKFetchBaseQueryError,
} from '@/utils/error'
import { exhaustiveCheck } from '@/utils/exhaustive-check'
import { FacebookLoginCallbackReturnValues } from '@/utils/facebook/create-facebook-login-callback'
import logger from '@/utils/logger'
import { useOpenAuthorizationIncompleteModal } from '@/utils/open-authorization-incomplete-modal'

const PC_CARD_BODY_WIDTH = 400

type SsoSuccessCallback<Value> = (value: Value) => Promise<void> | void

type LoginViewProps = {
  userGroup: UserGroup
  onFacebookSuccess: SsoSuccessCallback<FacebookSocialLoginPayload>
  onFacebookFail: (error: unknown) => void
  onGoogleSuccess: SsoSuccessCallback<GoogleSocialLoginPayload>
  onGoogleFail: (error: unknown) => void
  onMailSuccess: (value: LoginResponse) => void
  onMailFail: (error: unknown) => void
}

const LoginView: FunctionComponent<LoginViewProps> = ({
  userGroup,
  onFacebookSuccess,
  onFacebookFail,
  onGoogleSuccess,
  onGoogleFail,
  onMailSuccess,
  onMailFail,
}) => {
  const { language } = useI18n()
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()

  const authFormId = useId()
  const [isAuthorizing, setIsAuthorizing] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const isPC = breakpoint === 'PC'

  const facebookSocialLogin = useFacebookSocialLogin()
  const googleSocialLogin = useGoogleSocialLogin()
  const [login] = useLoginMutation()

  const openAuthorizationIncompleteModal = useOpenAuthorizationIncompleteModal()

  const handleTabChange = (value: UserGroup): void => {
    routes.login.goto({}, { userGroup: value, lang: language.i18nCode })
  }

  const handleFacebookAuthorizeSuccess = async (
    data: FacebookLoginCallbackReturnValues,
  ): Promise<void> => {
    try {
      if (!data.email) {
        notification.error({
          message: formatMessage({ id: 'account:alert_email_not_granted' }),
          placement: 'bottomRight',
        })
        Cookies.set(CookieName.NeedAuthAgain, 'true')

        return
      } else {
        Cookies.remove(CookieName.NeedAuthAgain)
      }

      const payload: FacebookSocialLoginPayload = {
        oAuthType: 'fb',
        oAuthUserId: data.id,
        token: data.accessToken,
        email: data.email,
        userID: data.userID,
      }

      setIsAuthorizing(true)
      await facebookSocialLogin(payload, {
        language: language.i18nCode,
        type: userGroup === 'ad' ? UserType.NormalAd : undefined,
      })

      await onFacebookSuccess(payload)
    } catch (error) {
      onFacebookFail(error)
      logger.error(error)
    } finally {
      setIsAuthorizing(false)
    }
  }

  const handleInstagramAuthorizeSuccess = handleFacebookAuthorizeSuccess

  const handleGoogleAuthorizeSuccess = async (
    data: GoogleSocialData,
  ): Promise<void> => {
    try {
      if (!data.email) {
        notification.error({
          message: formatMessage({ id: 'account:alert_email_not_granted' }),
          placement: 'bottomRight',
        })
        Cookies.set(CookieName.NeedAuthAgain, 'true')

        return
      } else {
        Cookies.remove(CookieName.NeedAuthAgain)
      }

      const payload: GoogleSocialLoginPayload = {
        oAuthType: 'google',
        oAuthUserId: data.id,
        token: data.accessToken,
        email: data.email,
      }

      setIsAuthorizing(true)
      await googleSocialLogin(payload, {
        language: language.i18nCode,
        type: userGroup === 'ad' ? UserType.NormalAd : undefined,
      })

      await onGoogleSuccess(payload)
    } catch (error) {
      onGoogleFail(error)
      logger.error(error)
    } finally {
      setIsAuthorizing(false)
    }
  }

  const handleFacebookAuthorizationFail = (error: unknown): void => {
    Cookies.set(CookieName.NeedAuthAgain, 'true')
    openAuthorizationIncompleteModal('Facebook')

    logger.error(error)
  }

  const handleInstagramAuthorizationFail = (error: unknown): void => {
    /** TODO: remove this if underlying SDK has been replaced with real instagram API. */
    Cookies.set(CookieName.NeedAuthAgain, 'true')
    openAuthorizationIncompleteModal('Instagram')

    logger.error(error)
  }

  const handleGoogleAuthorizationFail = (error: unknown): void => {
    logger.error(error)
  }

  const renderSocialLoginButtons = (): ReactNode => {
    switch (userGroup) {
      case 'ad':
        return (
          <>
            <SsoFacebookButton
              actionType='login'
              fields={['email', 'name']}
              scopes={META_AD_AUTH_SCOPES}
              onAuthorizationFail={handleFacebookAuthorizationFail}
              onAuthorizationSuccess={handleFacebookAuthorizeSuccess}
            />
            <SsoGoogleButton
              actionType='login'
              onAuthorizationFail={handleGoogleAuthorizationFail}
              onAuthorizationSuccess={handleGoogleAuthorizeSuccess}
            />
          </>
        )
      case 'kol':
        return (
          <>
            <SsoFacebookButton
              actionType='login'
              fields={['email', 'name']}
              scopes={META_KOL_AUTH_SCOPES}
              onAuthorizationFail={handleFacebookAuthorizationFail}
              onAuthorizationSuccess={handleFacebookAuthorizeSuccess}
            />
            <SsoInstagramButton
              actionType='login'
              fields={['email', 'name']}
              scopes={META_KOL_AUTH_SCOPES}
              onAuthorizationFail={handleInstagramAuthorizationFail}
              onAuthorizationSuccess={handleInstagramAuthorizeSuccess}
            />
          </>
        )
      default:
        return exhaustiveCheck(userGroup)
    }
  }

  const handleLoginFormFinish = async (
    values: LoginFormValues,
  ): Promise<void> => {
    setIsAuthorizing(true)
    setErrorMessage(null)

    try {
      const response = await login(values).unwrap()
      onMailSuccess(response)
    } catch (error) {
      onMailFail(error)

      if (isRTKFetchBaseQueryError(error) && error.status === 403) {
        setErrorMessage(formatMessage({ id: 'account:login_suspended_msg' }))

        return
      }

      if (hasFailedAuthenticationError(error)) {
        setErrorMessage(formatMessage({ id: 'error:login_email_psw_fail' }))

        return
      }

      logger.error(error)
    } finally {
      setIsAuthorizing(false)
    }
  }

  const contentEl = (
    <Space direction='vertical' size='large' style={{ width: '100%' }}>
      <Space
        align='center'
        direction='vertical'
        size={12}
        style={{ width: '100%' }}
      >
        <H2>{formatMessage({ id: 'general:login' })}</H2>
      </Space>
      <Space direction='vertical' size='middle' style={{ width: '100%' }}>
        {renderSocialLoginButtons()}
      </Space>
      <StyledDivider>
        {formatMessage({ id: 'account:register_or_sso' })}
      </StyledDivider>
      <Flex vertical={true}>
        {errorMessage && (
          <ErrorAlert
            showIcon
            icon={<Icon fontSize={24} name='alert-circle' />}
            message={errorMessage}
            type='error'
          />
        )}
        <LoginForm id={authFormId} onFinish={handleLoginFormFinish} />
        <LoginAuthInfoFormFooter formId={authFormId} loading={isAuthorizing} />
      </Flex>
    </Space>
  )

  if (isPC) {
    return (
      <Card
        renderHeader={() => (
          <LoginDesktopIdentityTabs
            value={userGroup}
            onChange={handleTabChange}
          />
        )}
      >
        <CardBody>{contentEl}</CardBody>
      </Card>
    )
  }

  return (
    <MobileBody>
      <LoginMobileIdentityTabs value={userGroup} onChange={handleTabChange} />
      {contentEl}
    </MobileBody>
  )
}

const CardBody = styled.div`
  width: ${PC_CARD_BODY_WIDTH}px;
  margin: auto;
`

const MobileBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

const StyledDivider = styled(Divider)`
  margin: 0 !important;

  .ant-divider-inner-text {
    color: ${({ theme }): string => theme.colors.base.grey.grey4};
    font-size: 14px;
    font-weight: normal;
  }
`
const ErrorAlert = styled(Alert)`
  border: none;
  padding: 16px;
  margin-bottom: 16px;

  span {
    margin-right: 8px;
  }

  .ant-alert-message {
    font-size: 15px;
    line-height: 24px;
  }

  span,
  .ant-alert-message {
    color: ${({ theme }): string => theme.colors.base.red.red1};
  }
`

export default LoginView
