import { useGoogleLogin } from '@react-oauth/google'
import { useFetchGoogleUserInfoLazyQuery } from '@/api/google-api'

export interface GoogleSocialData {
  email: string
  accessToken: string
  id: string
}

const useGoogleOAuthLogin = (
  onSuccess: (socialData: GoogleSocialData) => void,
  onError?: (error: unknown) => void,
): (() => void) => {
  const [fetchGoogleUserInfo] = useFetchGoogleUserInfoLazyQuery()
  const login = useGoogleLogin({
    onSuccess: async (res) => {
      try {
        const userInfo = await fetchGoogleUserInfo(res.access_token).unwrap()
        onSuccess({
          email: userInfo.email,
          id: userInfo.sub,
          accessToken: res.access_token,
        })
      } catch (error) {
        console.error(error)
      }
    },
    onError: (error: unknown) => {
      console.error(error)
      onError?.(error)
    },
  })
  return login
}

export default useGoogleOAuthLogin
