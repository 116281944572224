import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useSocialLoginMutation } from '@/api/user-api'
import { CookieName } from '@/constants/cookie-name'
import { useDispatch } from '@/hooks/use-dispatch'
import { cacheJWT, cacheLoginTime } from '@/store/me'
import {
  SocialLoginResponse,
  SocialRegisterRequest,
} from '@/types/schema/login-schema'

export const useSocialLogin = (): ((
  payload: SocialRegisterRequest,
) => Promise<SocialLoginResponse>) => {
  const dispatch = useDispatch()

  const [socialLogin] = useSocialLoginMutation()

  return useCallback(
    async (payload: SocialRegisterRequest): Promise<SocialLoginResponse> => {
      const response = await socialLogin(payload).unwrap()

      const { token, createTime } = response

      dispatch(cacheJWT(token))
      dispatch(cacheLoginTime(`${createTime}`))

      Cookies.set(CookieName.IsSingleLogin, 'true')

      return response
    },
    [dispatch, socialLogin],
  )
}
