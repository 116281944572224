import {
  FacebookAuthResponse,
  FacebookUser,
  FacebookUserField,
} from '@/types/facebook'
import { getFacebookLoginOptions } from '@/utils/facebook/get-facebook-login-options'
import { getFacebookUser } from '@/utils/facebook/get-facebook-user'
import { loginFacebook } from '@/utils/facebook/login-facebook'

export type FacebookLoginCallbackOptions = {
  scopes: string[]
  fields: FacebookUserField[]
}

export type FacebookLoginCallbackReturnValues = FacebookAuthResponse &
  FacebookUser

export const createFacebookLoginCallback = (
  options: FacebookLoginCallbackOptions,
) => {
  return async (): Promise<FacebookLoginCallbackReturnValues> => {
    const { scopes, fields } = options

    const { status, authResponse } = await loginFacebook(
      getFacebookLoginOptions({ scope: scopes.join(',') }),
    )

    if (status !== 'connected' || !authResponse.accessToken) {
      throw Error('Facebook authorize failed')
    }

    const user = await getFacebookUser(fields)

    return { ...authResponse, ...user }
  }
}
