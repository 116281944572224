import Cookies from 'js-cookie'
import { CookieName } from '@/constants/cookie-name'
import { FacebookLoginOptions } from '@/types/facebook'

export const getFacebookLoginOptions = (
  options: FacebookLoginOptions,
): FacebookLoginOptions => {
  return {
    ...options,
    return_scopes: true,
    auth_type: Cookies.get(CookieName.NeedAuthAgain) ? 'rerequest' : undefined,
  }
}
