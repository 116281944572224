import { Form } from 'antd'
import { FormProps } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import MailField from '@/components/form/fields/mail-field'
import PasswordField from '@/components/form/fields/password-field'

export type LoginFormValues = {
  email: string
  password: string
}

type LoginFormProps = FormProps<LoginFormValues>

const LoginForm: FunctionComponent<LoginFormProps> = ({
  form: derivedForm,
  ...restProps
}) => {
  const [form] = useForm<LoginFormValues>(derivedForm)

  return (
    <Wrapper>
      <Form form={form} {...restProps}>
        <MailField />
        <PasswordField />
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;

  .ant-form {
    > .ant-form-item {
      margin-bottom: 16px;
    }

    > div:last-child {
      margin-bottom: 0;
    }
  }
`

export default LoginForm
