import { skipToken } from '@reduxjs/toolkit/query'
import { useFetchTrialCodeInfoQuery } from '@/api/trial-code-api'
import { useFetchUserStatusQuery } from '@/api/user-api'
import LocalStorageKeys from '@/constants/localstorage-keys'
import useLocalStorageSafely from '@/hooks/use-local-storage-safely'

export const useCurrentTrialCodeInfoQuery = (): ReturnType<
  typeof useFetchTrialCodeInfoQuery
> => {
  const { data: userStatus } = useFetchUserStatusQuery()
  const [storedCode] = useLocalStorageSafely<string>(
    LocalStorageKeys.TrialCode,
    undefined,
  )

  const trialCode = storedCode ?? userStatus?.queuedTrialCode

  return useFetchTrialCodeInfoQuery(trialCode ? { code: trialCode } : skipToken)
}
