import { useCallback } from 'react'
import { useSetFacebookTokenMutation } from '@/api/user-api'
import { UserType } from '@/hooks/use-authorization/constants'
import { useSocialLogin } from '@/hooks/use-social-login'
import { SupportedLanguages } from '@/i18n/config'
import { FacebookAuthResponse, FacebookUser } from '@/types/facebook'
import { SocialLoginResponse } from '@/types/schema/login-schema'

export type FacebookSocialLoginPayload = {
  oAuthType: 'fb'
  oAuthUserId: FacebookUser['id']
  token: FacebookAuthResponse['accessToken']
  email: FacebookUser['email']
  userID: FacebookAuthResponse['userID']
  trial_code?: string
}

export const useFacebookSocialLogin = (): ((
  socialData: FacebookSocialLoginPayload,
  {
    language,
    type,
    trial_code,
  }: { language: SupportedLanguages; type?: UserType; trial_code?: string },
) => Promise<SocialLoginResponse>) => {
  const socialLogin = useSocialLogin()
  const [setFacebookToken] = useSetFacebookTokenMutation()

  return useCallback(
    async (
      socialData: FacebookSocialLoginPayload,
      {
        language,
        type,
        trial_code,
      }: { language: SupportedLanguages; type: UserType; trial_code?: string },
    ): Promise<SocialLoginResponse> => {
      const response = await socialLogin({
        ...socialData,
        language,
        type,
        trial_code,
        oAuthUserId: `${socialData.oAuthUserId}`,
      })

      await setFacebookToken({
        accessToken: socialData.token ?? '',
        userID: socialData.userID,
      })

      return response
    },
    [setFacebookToken, socialLogin],
  )
}
