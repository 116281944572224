import { notification } from 'antd'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { StringParam, useQueryParams } from 'use-query-params'
import { LoginLayout } from '@/components/layout/entry-layout'
import LoginView from '@/components/login/login-view'
import { CookieName } from '@/constants/cookie-name'
import routes from '@/hooks/use-authorization/routes'
import { useDispatch } from '@/hooks/use-dispatch'
import { cacheJWT, cacheLoginTime } from '@/store/me'
import { LoginResponse } from '@/types/api-v1-types'
import { NextLayoutComponentType } from '@/types/next-layout-component-type'
import { isUserGroup } from '@/utils/accounting/is-user-group'
import { ampli } from '@/utils/ampli'

const Login: NextLayoutComponentType = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [query] = useQueryParams({
    redirect: StringParam,
  })
  const redirectUrl = query.redirect
  const { userGroup: userGroupQuery } = router.query
  const userGroup = isUserGroup(userGroupQuery) ? userGroupQuery : 'ad'
  const { formatMessage } = useIntl()

  useEffect(() => {
    ampli.viewLoginScreen({ userGroup })
  }, [userGroup])

  const handleSsoLoginSuccess = (): void => {
    routes.default.goto({})
  }

  const handleEmailLoginSuccess = async (
    response: LoginResponse,
  ): Promise<void> => {
    const { token, createTime } = response
    Cookies.set(CookieName.IsSingleLogin, 'false')
    dispatch(cacheJWT(token))
    dispatch(cacheLoginTime(createTime))

    ampli.loginSuccess({ type: 'email' })

    notification.success({
      message: formatMessage({ id: 'general:login_successfully' }),
      placement: 'bottomRight',
    })

    routes.default.goto({}, redirectUrl ? { redirect: redirectUrl } : undefined)
  }

  const handleEmailLoginFail = (): void => {
    ampli.loginFail({ type: 'email' })
  }

  const handleGoogleLoginFail = (): void => {
    ampli.loginFail({ type: 'social', ssoPlatform: 'google' })
  }

  const handleFacebookLoginFail = (): void => {
    ampli.loginFail({ type: 'social', ssoPlatform: 'facebook' })
  }

  return (
    <Container>
      <LoginView
        userGroup={userGroup}
        onFacebookFail={handleGoogleLoginFail}
        onFacebookSuccess={handleSsoLoginSuccess}
        onGoogleFail={handleFacebookLoginFail}
        onGoogleSuccess={handleSsoLoginSuccess}
        onMailFail={handleEmailLoginFail}
        onMailSuccess={handleEmailLoginSuccess}
      />
    </Container>
  )
}

Login.getLayout = (page): ReactNode => <LoginLayout>{page}</LoginLayout>
export default Login

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
