import { Icon } from '@buggy/shared'
import { Flex, Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FunctionComponent } from 'react'
import { Item } from '@/components/common/utils'
import { SupportedLanguages } from '@/i18n/config'
import { languages } from '@/i18n/languages'

const makeLanguageOption = (
  language: Item<typeof languages>,
): DefaultOptionType => {
  return {
    label: (
      <Flex align='center' gap={8}>
        <Icon fontSize={16} name='globe' />
        {language.label}
      </Flex>
    ),
    value: language.code,
  }
}

interface LanguageSelectProps {
  defaultValue?: SupportedLanguages
  className?: string
  onSelect(value: SupportedLanguages): void
}

export const LanguageSelect: FunctionComponent<LanguageSelectProps> = ({
  className,
  defaultValue,
  onSelect,
}) => {
  return (
    <Select<SupportedLanguages>
      className={className}
      defaultValue={defaultValue}
      options={languages.map(makeLanguageOption)}
      style={{ width: 144 }}
      onSelect={(value) => onSelect(value)}
    />
  )
}
