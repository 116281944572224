import { FacebookUser, FacebookUserField } from '@/types/facebook'

export const getFacebookUser = (
  fields: FacebookUserField[],
): Promise<FacebookUser> => {
  return new Promise((resolve) => {
    window.FB.api('/me', { fields }, (response) => {
      resolve(response)
    })
  })
}
