import { FacebookLoginOptions, FacebookStatusResponse } from '@/types/facebook'

export const loginFacebook = (
  options: FacebookLoginOptions,
): Promise<FacebookStatusResponse> => {
  return new Promise((resolve) => {
    window.FB.login((response) => {
      resolve(response)
    }, options)
  })
}
